.course-table-contents h2 {
  font-family: "Source Serif Pro";
  font-weight: bold;
  font-size: 40px;
}

/* .course-table-contents > ol > li {
  font-family: 'Source Serif Pro';
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 5px;
} */

.course-table-contents ul {
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
