.cover-editor > .ql-bubble .ql-editor h1 {
  font-family: "Outfit";
  line-height: 1;
  font-weight: bold;
  font-size: 70px;
}

.cover-editor > .ql-bubble .ql-editor h2 {
  font-family: "Outfit";
  font-size: 54px;
  line-height: 1.1;
  font-weight: bold;
}

.cover-editor > .ql-bubble .ql-editor h3 {
  font-family: "Outfit";
  font-size: 40px;
  font-weight: bold;
  line-height: 1.1;
}

.cover-editor > .ql-bubble .ql-editor h4 {
  font-family: "Outfit";
  font-size: 32px;
  font-weight: bold;
  line-height: 1.1;
}

.cover-editor > .ql-bubble .ql-editor h5 {
  font-family: "Outfit";
  font-size: 24px;
  font-weight: bold;
  line-height: 1.1;
}

.cover-editor > .ql-bubble .ql-editor h6 {
  font-family: "Outfit";
  font-size: 18px;
  font-weight: bold;
  line-height: 1.1;
}

.cover-editor > .ql-bubble .ql-editor p {
  font-family: "Outfit";
  font-size: 18px;
  line-height: 1.5;
}

.cover-editor > .ql-bubble .ql-editor ol li {
  font-family: "Outfit";
  font-size: 18px;
  line-height: 1.3;
  margin-top: 15px;
}

.cover-editor > .ql-bubble .ql-editor ul li {
  font-family: "Outfit";
  font-size: 18px;
  line-height: 1.3;
  margin-top: 15px;
}

.cover-editor > .ql-bubble .ql-editor div {
  font-family: "Outfit";
  font-size: 18px;
  /* line-height: 1.5; */
}

/* Universal template styling */

/* Should be able to override */
/* .cover-editor > .ql-container.ql-bubble > * {
  font-family: 'Outfit';
} */

.cover-editor > .ql-toolbar.ql-bubble {
  border: none;
}

.cover-editor > .ql-container.ql-bubble {
  border: none;
}

.cover-editor > .ql-container.ql-bubble .ql-toolbar {
  font-family: "Outfit";
}

.cover-editor .ql-font-Outfit {
  font-family: "Outfit";
}
