.learn-keypoints:hover {
  text-decoration: underline;
  color: blue;
}

.learn-markdown * {
  font-family: "Source Serif Pro", serif;
  line-height: 1.6;
}

.learn-markdown h2,
.learn-markdown h3,
.learn-markdown h4 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
}

.learn-markdown p {
  font-size: 17px;
}

.learn-markdown li {
  line-height: 1.6;
  font-size: 16px;
}

.learn-markdown h4 {
  font-size: 18px;
}

.rc-md-editor {
  height: calc(100vh - 200px);
}
