.studio-project-root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 900px;
  margin: auto;
}

.studio-project-card-parent {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.studio-add-new-project-root {
  height: 100%;
  width: 100%;
  max-width: 600px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  flex: auto;
  align-self: center;
}

.projects-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: scroll;
  padding-right: 10px;
  max-height: calc(100vh - 300px);
}

.add-new-project-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media screen and (max-width: 600px) {
  .projects-list {
    max-height: calc(100vh - 220px);
  }
}
