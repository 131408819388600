.chat-markdown p {
  font-size: 16px;
  line-height: 1.2;
}

.markdown-component * {
  font-family: "Source Serif Pro", serif;
  line-height: 1.6;
}

.markdown-component h1 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 24x;
}

.markdown-component h2 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 22px;
}

.markdown-component h3 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 20px;
}

.markdown-component h4 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 19px;
}

.markdown-component p {
  font-size: 18px;
}

.markdown-component li {
  line-height: 1.6;
  font-size: 18px;
}

.overview-markdown * {
  font-family: "Source Serif Pro", serif;
  line-height: 1.6;
}

.overview-markdown h1 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 21px;
}

.overview-markdown h2 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 19px;
}

.overview-markdown h3 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 17px;
}

.overview-markdown h4 {
  font-family: "Source Serif Pro", serif;
  line-height: 1.9;
  font-size: 16px;
}

.overview-markdown p {
  font-size: 15px;
}

.overview-markdown li {
  line-height: 1.6;
  font-size: 15px;
}