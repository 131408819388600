.home-hero-font {
  padding: 0 40px;
  font-family: unset;
}

.section-title {
  font-family: "Gabarito";
  color: black;
  font-size: calc(42px + (40 - 36) * (100vw - 600px) / (600));
  text-align: center;
  align-self: center;
  font-weight: 700;
  margin: 50px auto;
}

.home-title {
  color: rgb(3, 144, 255);
  font-size: 70px;
  -webkit-text-fill-color: transparent;
  background: #020202;
  background: linear-gradient(270deg, #27abe5 13%, #dc00ff 200%);
  -webkit-background-clip: text;
  font-family: "Gabarito";
  margin-bottom: 10px;
}

.home-v3>* {
  font-family: Gabarito !important;
}