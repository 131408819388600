.content-editor > .ql-bubble .ql-editor h1 {
  font-family: "Outfit", serif;
  line-height: 1.2;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 32px;
}

.content-editor > .ql-bubble .ql-editor h2 {
  font-family: "Outfit", serif;
  font-size: 30px;
  line-height: 1.3;
  font-weight: bold;
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor h3 {
  font-family: "Outfit", serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor h4 {
  font-family: "Outfit", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor h5 {
  font-family: "Outfit", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor p {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor ol li {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 10px;
}

.content-editor > .ql-bubble .ql-editor ol {
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor ul li {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.4;
  margin-top: 5px;
}

.content-editor > .ql-bubble .ql-editor ul {
  margin-bottom: 20px;
}

.content-editor > .ql-bubble .ql-editor div {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* .content-editor > .ql-bubble .ql-editor img {
  margin: 0 15px 0 15px;
}

.content-editor > .ql-bubble .ql-editor {
  overflow: hidden;
} */

/* Universal template styling */

/* Should be able to override */
/* .content-editor > .ql-container.ql-bubble > * {
  font-family: 'Source Serif Pro', serif;
} */

/* Show only on font selection enabled */
/* .content-editor > .ql-bubble .ql-picker.ql-font {
  width: 130px;
} */

.content-editor > .ql-toolbar.ql-bubble {
  border: none;
}

.content-editor > .ql-container.ql-bubble {
  border: none;
}

.content-editor > .ql-container.ql-bubble .ql-toolbar {
  font-family: "Outfit";
}

.content-editor .ql-font-SourceSerifPro {
  font-family: "Source Serif Pro";
}

.content-editor .ql-font-Outfit {
  font-family: "Outfit";
}

.content-editor
  .ql-picker.ql-font
  .ql-picker-label[data-value="SourceSerifPro"]::before,
.content-editor
  .ql-picker.ql-font
  .ql-picker-item[data-value="SourceSerifPro"]::before {
  font-family: "Outfit";
  content: "Source Serif Pro" !important;
}

.content-editor
  .ql-picker.ql-font
  .ql-picker-label[data-value="Outfit"]::before,
.content-editor
  .ql-picker.ql-font
  .ql-picker-item[data-value="Outfit"]::before {
  font-family: "Outfit";
  content: "Outfit" !important;
}
