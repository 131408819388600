.pricing p {
  font-size: 18px;
  line-height: 1.5;
}

.pricing-hero-font {
  font-size: 45px;
  font-family: Outfit;
  margin-bottom: 80px;
  width: 80%;
  text-align: center;
  margin: auto auto 80px auto;
}

.api-plan-card > .ant-card-actions {
  background: #fff8d3 !important;
}

@media screen and (max-width: 600px) {
  .pricing-hero-font {
    font-size: 38px;
  }
}
