.overview-editor > .ql-bubble .ql-editor h1 {
  font-family: "Outfit", serif;
  line-height: 1.2;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 32px;
}

.overview-editor > .ql-bubble .ql-editor h2 {
  font-family: "Outfit", serif;
  font-size: 30px;
  line-height: 1.3;
  font-weight: bold;
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor h3 {
  font-family: "Outfit", serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor h4 {
  font-family: "Outfit", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor h5 {
  font-family: "Outfit", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor p {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor ol li {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 10px;
}

.overview-editor > .ql-bubble .ql-editor ol {
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor ul li {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.4;
  margin-top: 5px;
}

.overview-editor > .ql-bubble .ql-editor ul {
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor div {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Markdown */

.overview-md-editor > h1 {
  font-family: "Outfit", serif;
  line-height: 1.2;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 32px;
}

.overview-md-editor > h2 {
  font-family: "Outfit", serif;
  font-size: 30px;
  line-height: 1.3;
  font-weight: bold;
  margin-bottom: 20px;
}

.overview-md-editor > h3 {
  font-family: "Outfit", serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 20px;
}

.overview-md-editor > h4 {
  font-family: "Outfit", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.overview-md-editor > h5 {
  font-family: "Outfit", serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

.overview-md-editor > p {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.overview-md-editor > ol li {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-top: 10px;
}

.overview-md-editor > ol {
  margin-bottom: 20px;
}

.overview-md-editor > ul li {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.4;
  margin-top: 5px;
}

.overview-md-editor > ul {
  margin-bottom: 20px;
}

.overview-md-editor > div {
  font-family: "Source Serif Pro", serif;
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.overview-editor > .ql-bubble .ql-editor {
  padding: 0;
}

.overview-editor > .ql-toolbar.ql-bubble {
  border: none;
}

.overview-editor > .ql-container.ql-bubble {
  border: none;
}

.overview-editor > .ql-container.ql-bubble .ql-toolbar {
  font-family: "Outfit";
}
